import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import c_hall_logo from "../../media/Asset8@2x.png";
import cosentino_logo from '../../media/Asset9@2x.png';
import './Streaming.scss';
import { languages } from '../../common/constants/languages';

export class Streaming extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    const language = this.props.match.params.lang;
    //const dateTime = new Date(`${process.env.REACT_APP_ACCESS_EVENT_DATE}`);
    //if ((dateTime > new Date(Date.now())) ||
    //  (language !== languages.es && language !== languages.en)) {
    //  props.history.push('/language');
    // }
  }

  render() {
    let language = this.props.match.params.lang;
    const isEnglish = language === languages.en;
    const videoUrl = isEnglish ? process.env.REACT_APP_YOUTUBE_URL_EN : process.env.REACT_APP_YOUTUBE_URL_ES;
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}>
        <div className='streaming'>
          <img className='logo__c-hall' src={c_hall_logo} alt="C-Hall Logo" />
          <div className='video-container'>
            <iframe
              width="560"
              height="315"
              src={videoUrl + '?autoplay=1'}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
          </div>
          <img className='logo__cosentino' src={cosentino_logo} alt="Cosentino Logo" />
        </div>
      </MsalAuthenticationTemplate>
    )
  }
}
