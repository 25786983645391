import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { routes } from './routes';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './common/msal/msal';
import { initializeIcons } from '@uifabric/icons';
import './styles/styles.scss';
import { datadogRum } from '@datadog/browser-rum';

renderApp();

function renderApp() {

    initializeIcons();
    console.log('renderApp');
    datadogRum.init({
        applicationId: 'e12af4b2-4390-4782-8795-aa4e7db5c1ed',
        clientToken: 'pub341a111278e547d5a11a8263abaee445',
        site: 'datadoghq.com',
        service:'chall',
        env:'pro',
        // Specify a version number to identify the deployed version of your application in Datadog 
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
    ReactDOM.render(
        <MsalProvider instance={msalInstance}>
            <BrowserRouter children={routes} />
        </MsalProvider>,
        document.getElementById('root'),
    );
}
